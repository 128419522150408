import React from "react";
import { FormattedMessage } from "react-intl";
import ActivityList from "../../Partials/ActivityList";

export default function SectionActivity() {
  return (
    <section className="section-activity">
      <img
        alt="bg"
        className="background-img2"
        src="/images/background-missgrand2.png"
      />
      <div className="bs-container">
        <div className="bs-row">
          <div className="bs-col">
            <div className="module module-activity">
              <div className="module-header" data-aos="fade-up">
                <span className="id">02</span>
                <h2 className="title">
                  <FormattedMessage id="index.recently" />
                </h2>
              </div>
              <div className="module-content" data-aos="fade-up">
                <div className="table-content">
                  <div className="table-head">
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          <th>Thí sinh được bình chọn</th>
                          <th>Mã bình chọn</th>
                          <th>Người bình chọn</th>
                          <th>Thời gian</th>
                        </tr>
                        <ActivityList />
                      </thead>
                    </table>
                  </div>
                  {/* <table class="table">
                    <tbody>
                     
                    </tbody>
                  </table> */}

                  {/* <table class="table">
                  
                    <tbody>
                      <tr data-aos="fade-right" class="aos-init aos-animate">
                        <td>
                          <a
                            class="link"
                            href="/phieu-binh-chon/679a771fa04c356fa877cb162e53420fb6d4dfffaffd9508b1db9d442c2e9e4c"
                          >
                            1a3f6b76-43ee-4de0-b13d-ebd94c0025ee
                          </a>
                        </td>
                        <td>
                          <a
                            class="link"
                            href="/nguoi-binh-chon/85EA43C24E54CF8676E9917B299B545956AFAD39"
                          >
                            85EA43C24E54CF8676E9917B299B545956AFAD39
                          </a>
                        </td>
                        <td>Phòng kỹ thuật</td>
                        <td>17/09/2020 09:59</td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            class="link"
                            href="/phieu-binh-chon/43ef23ca2c81c2b1bc94a894e0f5396b7e25a6f205db930a6e2fb16396ab6c65"
                          >
                            1a0b163a-7207-45c0-a7b4-77d02f04eadd
                          </a>
                        </td>
                        <td>
                          <a
                            class="link"
                            href="/nguoi-binh-chon/84978953EB97A064547BEFBCD56CA989F2DF535B"
                          >
                            84978953EB97A064547BEFBCD56CA989F2DF535B
                          </a>
                        </td>
                        <td>Nguyễn Thúy Nga</td>
                        <td>17/09/2020 09:59</td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            class="link"
                            href="/phieu-binh-chon/5ce4055255fb606ca9564d1fcc9ccc78cbc8a5a88815faba45c76b7c24bd390b"
                          >
                            8d1fd4af-e4e8-4a33-a65b-1a6530df9cc5
                          </a>
                        </td>
                        <td>
                          <a
                            class="link"
                            href="/nguoi-binh-chon/85EA43C24E54CF8676E9917B299B545956AFAD39"
                          >
                            85EA43C24E54CF8676E9917B299B545956AFAD39
                          </a>
                        </td>
                        <td>Phòng kỹ thuật</td>
                        <td>17/09/2020 09:59</td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            class="link"
                            href="/phieu-binh-chon/6ec16833a38100727e16c11359a04cc349c6b9cf2a8ec0901fa5e55b47700ead"
                          >
                            2dd43514-8729-4974-8733-dd5998bb8c2c
                          </a>
                        </td>
                        <td>
                          <a
                            class="link"
                            href="/nguoi-binh-chon/84978953EB97A064547BEFBCD56CA989F2DF535B"
                          >
                            84978953EB97A064547BEFBCD56CA989F2DF535B
                          </a>
                        </td>
                        <td>Nguyễn Thúy Nga</td>
                        <td>17/09/2020 09:58</td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            class="link"
                            href="/phieu-binh-chon/70d9f2528208e9da0cd4acce82ee6f91e7dbdb78dd868ec55328aff11cd22f6f"
                          >
                            816e4ff9-1441-4e43-817d-70ef3ce26083
                          </a>
                        </td>
                        <td>
                          <a
                            class="link"
                            href="/nguoi-binh-chon/432F6418EC22F7CCDA91470F700A0A5949A97C49"
                          >
                            432F6418EC22F7CCDA91470F700A0A5949A97C49
                          </a>
                        </td>
                        <td>Lâm Hoàng Anh Thư</td>
                        <td>17/09/2020 09:58</td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>

              {/* <div className="activity-advertisement">
                <Slider {...ACTIVITY_SLIDE_SLICK} className="activity-slide">
                  <div className="slide-item">
                    <div className="item">
                      <a
                        href="https://pk28bdienbienphu.com/"
                        target="__blank"
                        className="link"
                      >
                        <img
                          src="/images/banner_pkdk.jpg"
                          alt="pk28bdienbienphu"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="slide-item">
                    <div className="item">
                      <a
                        href="http://blockchain.bachkhoa-aptech.edu.vn"
                        target="__blank"
                        className="link"
                      >
                        <img
                          src="/images/banner_bytesoft.jpg"
                          alt="lap trinh ung dung blockchain"
                        />
                      </a>
                    </div>
                  </div>
                </Slider>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
