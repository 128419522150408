import styled, { css } from "styled-components";

const Button = styled.button`
  color: white;
  background: #cb8b28 !important;
  padding: 0 20px;
  min-height: 40px;
  border: unset;
  border-radius: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  border-radius: 4px;

  ${({ outline }) =>
    outline &&
    css`
      border: 1px solid #cb8b28;
      background: white !important;
      color: #cb8b28;
    `}

  .dropdown {
    display: none;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    transform: translateY(100%);
    background: #cb8b28;
    z-index: 1;
    li {
      border: 1px solid white;
      border-left: unset;
      border-right: unset;
      padding: 5px;
      opacity: 0.9;
      :hover {
        opacity: 1;
      }
    }
  }

  :hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3), -1px -1px 5px rgba(0, 0, 0, 0.3);
    background: #cb8b28;
    .dropdown {
      display: block;
    }
  }
`;

export default Button;
