/* eslint-disable jsx-a11y/anchor-has-content */
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
const SectionBrand = () => {
  const [ShowMore, setShowMore] = useState(true);
  return (
    <section
      className="section-branch aos-init aos-animate"
      data-aos="fade-down"
      data-aos-delay={0}
    >
      <div className="branch-content">
        <div className="bs-container">
          <div className="bs-row">
            <div className="bs-col">
              <div className="module module-branch">
                <div className="module-header" data-aos="fade-up">
                  <span className="id">04</span>
                  <h2 className="title">
                    <FormattedMessage id="index.partner" />
                  </h2>
                </div>
                <div className={`module-content ${ShowMore ? "active" : ""}`}>
                  <div className="branch-width">
                    <div className="branch-slide">
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bvote.com.vn/index.html"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/logo-header.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytesoft.vn/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/branch_item1.gif" alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/bytenext_logo.png" alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/reavol-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/jobbooking-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/sailfish-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/c3group-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/behome-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://avatarart.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/avatarart-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://avatarart.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/langro-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://avatarart.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/bytepay-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  className="show__more"
                  onClick={() => setShowMore(!ShowMore)}
                >
                  »
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionBrand;
