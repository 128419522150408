import React from "react";
import ReactHtmlParser from "react-html-parser";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import { MdLink } from "react-icons/md";
import LayoutSubPage from "../../Layouts/LayoutSubPage";
import SectionBanner from "../Index/SectionBanner";
import { INTRO, INTRO_TITLE } from "../../../config/contentHtml";
export default function Introduct() {
  return (
    <LayoutSubPage path="/gioi-thieu">
      <>
        {/* <section class=" banner-page">
          <div class="banner-content"></div>
        </section> */}
        <SectionBanner />
        <section className="section-rules">
          <div className="bs-container">
            <div className="bs-row">
              <div className="bs-col">
                <div
                  className="module module-rules aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-offset={-200}
                  data-aos-delay={300}
                >
                  <div
                    className="module-content aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="body" style={{ paddingBottom: 30 }}>
                      <p className="body-title">{INTRO_TITLE}</p>

                      {ReactHtmlParser(INTRO)}
                      <ul className="info-website">
                        <p className="title">
                          CÔNG TY CỔ PHẦN{" "}
                          <a
                            className="link"
                            href="https://bvote.com.vn/index.html"
                          >
                            BVOTE
                          </a>{" "}
                          VIỆT NAM
                        </p>
                        <li>
                          {/* <span>
                         <ImLocation2 className="icon"/> 
                         </span> */}
                          Tổ hợp văn phòng Sunsquare - Số 21 Lê Đức Thọ - Phường
                          Mỹ Đình 2 - Quận Nam Từ Liêm - Hà Nội. Bvote – Bình
                          chọn nhanh nhạy- Tin cậy người dùng
                        </li>
                        <li>
                          <span>
                            {" "}
                            <MdLink className="icon" />
                          </span>
                          Website: https://bvote.com.vn/
                        </li>
                        <li>
                          <span>
                            {" "}
                            <IoMdMail className="icon" />
                          </span>
                          Email: bvote@bvote.vn
                        </li>
                        <li>
                          <span>
                            <FaPhone className="icon" />
                          </span>
                          Hotline: +84 86 966 1866
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <SectionBrand /> */}
      </>
    </LayoutSubPage>
  );
}
