/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useVoteList } from "../../../store";
import LayoutSubPage from "../../Layouts/LayoutSubPage";
import VoteList from "../../Partials/VoteList";
import SectionBanner from "../Index/SectionBanner";
import ContestantDetail from "./ContestantDetail";

const Contestant = () => {
  const loader = (
    <div className="text-center ajaxLoading">
      <img
        width="200"
        alt="loading ..."
        src="/images/spinner.gif"
        style={{ display: "inline" }}
      />
    </div>
  );

  const { missId } = useParams();
  // const { contestants } = useContext(ContestantsContext);
  // const info = contestants.find((con) => con.code == missId) || {};
  // console.log("infoooo", info);

  const { voteList, isHasMore, getVoteList } = useVoteList();
  const [filter, setFilter] = useState({ pageNumber: 1, pageSize: 10 });

  useEffect(() => {
    getVoteList(missId, filter.pageNumber, filter.pageSize);
  }, [filter, getVoteList, missId]);
  useEffect(() => {
    const el = document.querySelector("#contestant-detail");
    el && el.scrollIntoView();
  }, []);
  return (
    <LayoutSubPage>
      {/* <section className=" banner-page">
        <div className="banner-content"></div>
      </section> */}
      <SectionBanner />
      <section className="section-detail">
        <div className="bs-container">
          <div className="bs-row">
            <div className="bs-col">
              <div className="module module-detail">
                <div className={`module-content`} id="contestant-detail">
                  <ContestantDetail />

                  <div className="detail-history">
                    <h3 className="title">
                      <FormattedMessage id="contestant.history" />
                    </h3>

                    <div className="history-table" id="data-container">
                      <InfiniteScroll
                        dataLength={voteList.length}
                        next={() => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNumber: prev.pageNumber + 1,
                          }));
                        }}
                        scrollThreshold={0.9}
                        hasMore={isHasMore}
                        loader={loader}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>
                              <FormattedMessage id="none" />
                            </b>
                          </p>
                        }
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="contestant.index" />
                              </th>
                              <th>
                                <FormattedMessage id="voterId" />
                              </th>
                              <th>
                                <FormattedMessage id="time" />
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {voteList.map((el, index) => (
                              <VoteList
                                key={el._id}
                                index={index + 1}
                                display_account={el.voterId}
                                create_time={el.createdAt}
                              />
                            ))}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <SectionBrand /> */}
    </LayoutSubPage>
  );
};

export default Contestant;
