import React from "react";
import { Link } from "react-router-dom";
import { getAvatarUrl } from "../../store";

export default function ContestantCard({
  code,
  missId,
  missName,
  votePoints,
  favoritePoint,
}) {
  const content = votePoints || votePoints === 0 ? votePoints : "...";

  // console.log("missId", missId);

  return (
    <div className="bs-col lg-25-15 md-33-15 xs-50-15">
      <div className={`item aos-init aos-animate`} data-aos="fade-left">
        {/* <div className="item-content">
          <div className="head">
            <p className="sbd">
              SBD: <span>{missId}</span>
            </p>
          </div>
          <div className="content">
            <div className="img">
              <div class="box-shadow">
                <div className="img-frame">
                  <img
                    src={`/images/AVA/${configId}.jpg`}
                    
                    alt={missId}
                    loading="lazy"
                    className="image"
                  />
                </div>
              </div>
            </div>
            <div className="text">
              <p className="name">{missName}</p>
              <p className="point">
                Điểm bình chọn: <span>{content}</span>
              </p>
              <Link to={`/thi-sinh/${missId}`} className="link">
                Bình chọn
              </Link>
            </div>
          </div>
        </div> */}
        <div className="item-content">
          <div className="head"></div>
          <div className="content">
            <div className="img">
              <div className="box-shadow">
                <div className="img-frame">
                  <img
                    src={getAvatarUrl(code)}
                    alt={code}
                    loading="lazy"
                    className="image"
                  />
                </div>
              </div>
              <p className="sbd">
                SBD: <span>{code}</span>
              </p>
              {/* <p className="favourite">
                <BsFillSuitHeartFill className="icon" />
                <span>{favoritePoint}</span>
              </p> */}
            </div>
            <div className="text">
              <p className="name">{missName}</p>
              <p className="point">
                Điểm bình chọn: <span>{content}</span>
              </p>
              <Link to={`/thi-sinh/${missId}`} className="link">
                Bình chọn
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// src="/images/miss.png"
