import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function Navbar({ path }) {
  const [active, setActive] = useState(false);

  return (
    <React.Fragment>
      <header id="header" className="">
        {/* scrolled */}
        <div className="header-nav">
          <div className="bs-container">
            <div className="nav-content clearfix">
              <div className="logo">
                <Link to="/">
                  <img
                    // src="/images/logo.gif"
                    src="/images/logo-header.png"
                    alt=""
                    data-aos="fade-down"
                    data-aos-delay="300"
                  />
                </Link>
              </div>
              <div className="nav">
                <span className="show__menu" onClick={() => setActive(!active)}>
                  <i className="fas fa-bars" />
                </span>
                <div className={`menu ${active ? 'active' : ''}`}>
                  <span
                    className="close__menu"
                    onClick={() => setActive(false)}
                  >
                    <i className="far fa-window-close" />
                  </span>
                  <ul className="menu-list clearfix">
                    <li className="menu-list__item">
                      <Link
                        to="/"
                        className={`menu-list__link ${
                          path === '/' ? 'active' : ''
                        }`}
                      >
                        <FormattedMessage id="nav.home" />
                      </Link>
                    </li>
                    <li className="menu-list__item">
                      <Link
                        to="/gioi-thieu"
                        className={`menu-list__link ${
                          path === '/gioi-thieu' ? 'active' : ''
                        }`}
                      >
                        <FormattedMessage id="nav.intro" />
                      </Link>
                    </li>
                    {/* <li className="menu-list__item">
                      <span className="menu-list__link">
                        <FormattedMessage id="nav.intro" />
                      </span>
                      <ul className="dropdown-list">
                        <li className="dropdown-list__item">
                          <Link
                            to="/gioi-thieu"
                            className="dropdown-list__link"
                          >
                            <FormattedMessage id="nav.competition" />
                          </Link>
                        </li>
                        <li className="dropdown-list__item">
                          <Link
                            to="/don-vi-to-chuc"
                            className="dropdown-list__link"
                          >
                            <FormattedMessage id="nav.organization" />
                          </Link>
                        </li>
                        <li className="dropdown-list__item">
                          <Link
                            to="/ban-giam-khao"
                            className="dropdown-list__link"
                          >
                            <FormattedMessage id="nav.judges" />
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li className="menu-list__item">
                      <Link
                        to="/bang-xep-hang"
                        className={`menu-list__link ${
                          path === '/bang-xep-hang' ? 'active' : ''
                        }`}
                      >
                        <FormattedMessage id="nav.rank" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
