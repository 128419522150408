import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ContestantsContext } from "../../../App";
import ContestantCard from "../../Partials/ContestantCard";

export default function SectionEvent() {
  const { contestants, favorites } = useContext(ContestantsContext);

  return (
    <section className="section-event">
      <div className="bs-container">
        <div className="bs-row">
          <div className="bs-col">
            <div className="module module-event">
              <div className="module-header" data-aos="fade-up">
                <span className="id">03</span>
                <h2 className="title">
                  <FormattedMessage id="index.contestants" />
                </h2>
              </div>

              <div className="module-content">
                <div className="tab-content">
                  <div className="tab-item active">
                    <div className="bs-row row-xs-15">
                      {contestants.length ? (
                        contestants.map((el) => {
                          const favoritePoint =
                            favorites.find(
                              (f) => f.contestantId === el.contestantId
                            )?.totalVote || 0;

                          return (
                            <ContestantCard
                              key={el._id}
                              code={el.code}
                              missName={el.fullname}
                              votePoints={el.totalPoint}
                              missId={el.contestantId}
                              favoritePoint={favoritePoint}
                            />
                          );
                        })
                      ) : (
                        <div
                          className="bs-col lg-25-15 md-33-15 xs-50-15"
                          style={{
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          Không có
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
