import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import LayoutSubPage from "../../Layouts/LayoutSubPage";

import { request } from "../../../store/api/axios";

export class VoteDetail extends Component {
  state = {
    voteDetail: {},
    blockId: "",
    date: "",
  };

  async componentDidMount() {
    try {
      const { txId } = this.props.match.params;

      // const res = await axios.get(`/api/get/vote/${txId}`);

      const res = await request({
        method: "GET",
        url: `/api/get/vote/${txId}`,
      });

      const { voteDetail, date, blockId } = res.data;

      this.setState({ voteDetail, date, blockId });
    } catch (error) {
      console.log("xxx Get vote detail : ", error);
    }
  }

  render() {
    const { voteDetail, blockId, date } = this.state;

    // const title =
    //   this.props.lang === 'en'
    //     ? 'Vote detail - Poinsettia Festival English 2019'
    //     : 'Thông tin phiểu bình chọn - Tài hoa duyên dáng Dược 2023';

    return (
      <LayoutSubPage>
        <>
          <section class=" banner-page">
            <div class="banner-content"></div>
          </section>
          <section className="section-block">
            <div className="bs-container">
              <div className="bs-row">
                <div className="bs-col">
                  <div className="module module-block">
                    <div className="module-content">
                      <div className="vote-content block-content">
                        <div className="content-code">
                          <div className="bs-row row-md-15">
                            <div className="bs-col lg-50-15 md-55-15">
                              <div className="item">
                                <p className="desc">
                                  <FormattedMessage id="voteId" />
                                </p>
                                <p className="title">{voteDetail._id}</p>
                              </div>
                            </div>
                            <div className="bs-col lg-50-15 md-45-15">
                              <div className="item">
                                <p className="desc">
                                  <FormattedMessage id="block" />
                                </p>
                                {/* <Link
                                  to={`/khoi/${parseInt(blockId)}/${
                                    parseInt(blockId) + 2
                                  }`}
                                  className="title blue"
                                >
                                  {blockId}
                                </Link> */}

                                <p className="title">{blockId}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-content">
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="voterId" />
                                </th>
                                <th>
                                  <FormattedMessage id="voted" />
                                </th>
                                <th>
                                  <FormattedMessage id="time" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Link
                                    to={`/nguoi-binh-chon/${voteDetail.voterId}`}
                                  >
                                    {voteDetail.voterId}
                                  </Link>
                                </td>
                                <td> {voteDetail.contestantName}</td>
                                <td>{date} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <SectionBrand /> */}
        </>
      </LayoutSubPage>
    );
  }
}

export default VoteDetail;
